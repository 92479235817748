//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'
import ScrollTrigger from '../../node_modules/gsap/dist/ScrollTrigger'
import ResponsiveImageStrapi from '@/components/modules/ResponsiveImageStrapi'

export default {
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
	components: {
		ResponsiveImageStrapi,
	},

	computed: {
		posts() {
			// const re = this.$store.getters.cmsdata;
			// console.log('d index: ', re.home)
			return this.$store.getters.posts
		},
		posts_recent() {
			// const recent = Object.fromEntries(Object.entries(this.posts).slice(0, 3));
			const values = Object.values(this.posts)
			const sorted = values.sort((a, b) => a.published_at - b.published_at) // change order by swapping -
			// console.log('d index: ', sorted)
			return sorted.reverse().slice(0, 3)
		},
	},
	async asyncData({
		params,
		error,
		store
	}) {
		// console.log("async page _slug: ", params.slug);
		try {
			await store.dispatch('getPostsAll')
		} catch (e) {
			error({
				statusCode: e.statusCode,
				message: e.message,
			})
		}
	},
	mounted() {
		this.$nextTick(this.Intro)
	},
	methods: {
		Intro() {
			setTimeout(() => {
				gsap.registerPlugin(ScrollTrigger)
				const page = this.$refs.section.closest('.page')
				const tl = gsap.timeline({
					scrollTrigger: {
						scroller: page,
						trigger: this.$refs.section,
						start: "top center",
						// markers: true
					}
				});
				tl.fromTo(this.$refs.post, {
					autoAlpha: 0,
					y: 200
				}, {
					duration: 1.5,
					autoAlpha: 1,
					y: 0,
					stagger: 0.1
				});
			}, 0)
		},
	},
}

